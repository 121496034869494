import { createSlice } from '@reduxjs/toolkit';

export interface ModalData {
    [key: string]: any;
}

export interface SetModalPayload {
    name: keyof IState['modals'];
    data: ModalData;
}

export interface IState {
    modals: {
        logout: ModalData;
        meetingsDelete: ModalData;
        about: ModalData;
        language: ModalData;
        ifJoinMeeting: ModalData;
        confirmDelete: ModalData;
        maxSmsRecipients: ModalData;
        incorrectPhoneNumber: ModalData;
        alreadySmsContact: ModalData;
        accountTakeover: ModalData;
        resetPassword: ModalData;
        deleteFailedMessage: ModalData;
        forcedBreakMeeting: ModalData;
        sessionExpireTimeModal: ModalData;
        inviteContactModal: ModalData;
        disallowHideCallerId: ModalData;
        avConflict: ModalData;
        clickToDial: ModalData;
        canNotShowZoomPhone: ModalData;
        feedback: ModalData;
        optOutCallQueue: ModalData;
        addToCameraControlGroup: ModalData;
        upgradeMeetingChoose: ModalData;
        transferParticipantToWaitingRoom: ModalData;
        giveFeedback: ModalData;
        personalContactFromPhone: ModalData;
        personalContact: ModalData;
        deletePersonalContact: ModalData;
    };
    webclientIframeZIndex: number;
}

export const initialState: IState = {
    modals: {
        logout: null,
        meetingsDelete: null,
        about: null,
        language: null,
        ifJoinMeeting: null,
        confirmDelete: null,
        maxSmsRecipients: null,
        incorrectPhoneNumber: null,
        alreadySmsContact: null,
        accountTakeover: null,
        resetPassword: null,
        deleteFailedMessage: null,
        forcedBreakMeeting: null,
        disallowHideCallerId: null,
        sessionExpireTimeModal: { show: false, hasClickExpiredModalLater: false }, // hasClickExpiredModalLater : if click modal later button, the value is true
        avConflict: null,
        clickToDial: null,
        inviteContactModal: { show: false },
        canNotShowZoomPhone: { show: false },
        feedback: { show: false },
        optOutCallQueue: { show: false },
        addToCameraControlGroup: null,
        upgradeMeetingChoose: { show: false },
        transferParticipantToWaitingRoom: { show: false },
        giveFeedback: null,
        personalContactFromPhone: { show: false },
        personalContact: { show: false },
        deletePersonalContact: { show: false },
    },
    webclientIframeZIndex: 30000,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setModal(state, { payload }: { payload: SetModalPayload }) {
            const { name, data } = payload;
            if (!name) return;
            state.modals[name] = data;
        },
    },
});

export const { setModal } = modalSlice.actions;

export default modalSlice.reducer;
